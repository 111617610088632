/*
 * @Description:
 */

class EventEmitter {
  /* 订阅器 */
  subs = new Map();

  /**
    * 注册事件
    * @param {string} type 事件类型
    * @param {Function} callback 回调函数
    */
  $on (type: string, callback: any): void {
    const sub = this.subs.get(type)
    const isEmpty = sub && sub.push(callback)
    if (!isEmpty) {
      this.subs.set(type, [callback])
    }
  }

  /**
    * 触发事件
    * @param {string} type 事件类型
    * @param {Any} payload 传递的参数
    */
  $emit (type: string, ...payload: any): void {
    (this.subs.get(type) || []).forEach((fn:any) => { fn(...payload) });
    (this.subs.get('*') || []).forEach((fn:any) => { fn(...payload) }) /* 所有事件类型都执行 */
  }

  /**
    * 注销事件
    * @param {string} type 事件类型
    * @param {Function} callback 回调函数
    */
  $off (type: string, callback: any): void {
    const sub = this.subs.get(type)
    if (sub) {
      sub.splice(sub.indexOf(callback) >>> 0, 1)
    }
  }
}

export default EventEmitter
