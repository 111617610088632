/*
 * @Description:
 */

import EventEmitter from './EventEmitter'

class MessageHub extends EventEmitter {
  targetOrigin = ''
  $win: any = null;
  constructor (contentWindow: any, url: string) {
    super()
    this.targetOrigin = url || '*'
    this.$win = contentWindow
    this.__init()
  }

  __init (): void {
    window.addEventListener('message', (event) => {
      const origin = event.origin
      if (!['*', this.targetOrigin].includes(origin)) {
        return
      }

      const messageData = event.data
      if (typeof messageData !== 'object' || messageData.$$MessageType$$ !== 'MessageHub') {
        return
      }

      const {
        channel,
        data
      } = messageData

      this.$emit(channel, data)
    }, false)
  }

  $send (channel: string, data: any): void {
    const payload = {
      channel,
      data,
      $$MessageType$$: 'MessageHub'
    }

    this.$win.postMessage(payload, this.targetOrigin)
  }
}

export default MessageHub
