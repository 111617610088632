


























import { Component, Vue } from 'vue-property-decorator'
// import BaseHeader from '@/components/BaseHeader.vue'
import MainBody from '@/views/Home/components/MainBody.vue'
import Trait from '@/views/Home/components/Trait.vue'
// import Service from '@/views/Home/components/Service.vue'
// import Superiority from '@/views/Home/components/Superiority.vue'
import MessageHub from '../lib/MessageHub'

@Component({
  components: {
    // BaseHeader,
    MainBody,
    Trait
    // Service,
    // Superiority
  }
})
export default class WebIframe extends Vue {
  $eventHub: any

  private getHeight (): string {
    return document.body.scrollHeight + 'px'
  }

  private getOrigin () {
    try {
      return window.parent[0]?.location?.ancestorOrigins[0]
    } catch (error) {
      return 'https://www.yunlark.com'
    }
  }

  private postMessage () {
    this.$eventHub.$send('resize', this.getHeight())
  }

  private onReSize () {
    this.postMessage()
  }

  initEvent (): void {
    this.$eventHub = new MessageHub(window.parent, this.getOrigin())
    window.addEventListener('load', this.onReSize)
    window.addEventListener('resize', this.onReSize)
  }

  unEvent (): void {
    window.removeEventListener('resize', this.onReSize)
    window.removeEventListener('load', this.onReSize)
  }

  created (): void {
    if (window.top === window) {
      return
    }

    this.initEvent()
  }

  beforeDestroy (): void {
    this.unEvent()
  }
}
